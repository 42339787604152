/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useContext, useEffect } from "react";
import { useNavigate, Link, useLocation } from "react-router-dom";
import "./sidebar.css";
import { WebSocketContext } from "../../app/App";
import NavProfileImage from "./NavProfileImage";

const SidebarComp = () => {
  const { sideBarActive } = useContext(WebSocketContext);
  const user = JSON.parse(localStorage.getItem("user"));

  const ci = localStorage.getItem("A_companyinfo");
  const fname = localStorage.getItem("A_firstname");
  const lname = localStorage.getItem("A_lastname");
  const profile = localStorage.getItem("A_profile_image");

  const location = useLocation();

  // let COMPANYNAME = null;
  // if (ci && ci !== "undefined" && ci !== "null") {
  //   COMPANYNAME = JSON.parse(ci.replace(/\\/g, "")).company_name;
  // }

  const nav = useNavigate();

  // Define all menu items with their paths
  const menuItems = [
    { label: "Dashboard", path: "/dashboard" },
    { label: "Profile", path: "/dashboard/profile" },

    // Conditional menu items for admins
    // ...(user?.user_type === "ADMIN_USER"
    //   ? [
    //       { label: "Finance", path: "/dashboard/finance" },
    //       { label: "All Companies", path: "/dashboard/all-company" },
    //       { label: "Brand Management", path: "/dashboard/brand-management" },
    //       {
    //         label: "Categories Management",
    //         path: "/dashboard/categories-management",
    //       },
    //       {
    //         label: "Sub Categories Management",
    //         path: "/dashboard/sub-categories-management",
    //       },
    //       {
    //         label: "Product Management",
    //         path: "/dashboard/product-management",
    //       },
    //       { label: "Orders Management", path: "/dashboard/order-management" },
    //       { label: "Return Management", path: "/dashboard/return-management" },
    //       { label: "Admin Management", path: "/dashboard/admins-management" },
    //       { label: "User Management", path: "/dashboard/users-management" },

    //       { label: "Email Management", path: "/dashboard/email-management" },
    //       { label: "Web Settings", path: "/dashboard/web-settings" },
    //     ]
    //   : []),

    ...(user?.user_type === "superuser"
      ? [
          { label: "Admin Management", path: "/dashboard/admins-management" },
          // Add other items shared by both roles if necessary
        ]
      : []),
    ...(user?.user_type === "ADMIN_USER" || user?.user_type === "superuser"
      ? [
          { label: "All Companies", path: "/dashboard/all-company" },
          // Add other items shared by both roles if necessary
        ]
      : []),
    ...(user?.user_type === "ADMIN_USER" ||
    user?.user_type === "superuser" ||
    user?.user_type === "COMPANY_USER"
      ? [
          { label: "Finance", path: "/dashboard/finance" },
          { label: "Brand Management", path: "/dashboard/brand-management" },
          {
            label: "Categories Management",
            path: "/dashboard/categories-management",
          },
          {
            label: "Sub Categories Management",
            path: "/dashboard/sub-categories-management",
          },
          {
            label: "Product Management",
            path: "/dashboard/product-management",
          },
          { label: "Orders Management", path: "/dashboard/order-management" },
          { label: "Return Management", path: "/dashboard/return-management" },

          { label: "User Management", path: "/dashboard/users-management" },
          {
            label: "Subscriber Management",
            path: "/dashboard/subscriber-management",
          },
          { label: "Email Management", path: "/dashboard/email-management" },
          { label: "Web Settings", path: "/dashboard/web-settings" },
        ]
      : []),

    // You can add more routes here if needed
  ];

  useEffect(() => {
    const currentPath = location.pathname; // Current route
    const storedPath = localStorage.getItem("activeSidebarItem");

    // Only set a default value if nothing is stored
    if (!storedPath || storedPath === "undefined") {
      localStorage.setItem("activeSidebarItem", currentPath);
    } else if (storedPath !== currentPath) {
      // Sync `localStorage` with the current path if they differ
      localStorage.setItem("activeSidebarItem", currentPath);
    }
  }, [location.pathname]);

  // Store active item in localStorage when a menu item is clicked
  const handleMenuClick = (path) => {
    localStorage.setItem("activeSidebarItem", path);
  };

  return (
    <nav
      className={`sidebar sidebar-offcanvas sidebar-bg-color ${
        sideBarActive ? "active" : ""
      }`}
      id="sidebar"
    >
      <ul className="nav">
        {/* Profile Section */}
        <li className="nav-item nav-profile">
          <a href="#" className="nav-link">
            {/* <div className="nav-profile-image">
              <img src={profile} alt="profile" />
              <span className="login-status online"></span>
            </div> */}
            <NavProfileImage
              profile={profile || null}
              userName={fname || lname}
            />

            <div className="nav-profile-text d-flex flex-column">
              <span className="font-weight-bold mb-2">
                {fname} {lname}
              </span>
              <span className="text-secondary text-small">
                {user?.userType === "ADMIN_USER" ? "Admin" : "Admin"}
                {/* {userType === "ADMIN_USER" ? "Admin" : COMPANYNAME} */}
              </span>
            </div>
            <i className="mdi mdi-bookmark-check text-success nav-profile-badge"></i>
          </a>
        </li>

        {/* Dynamic Menu Items */}
        {menuItems.map((item, index) => (
          <li
            key={index}
            className={`nav-item ${
              location.pathname === item.path || // Exact match for the path
              (localStorage.getItem("activeSidebarItem") === item.path &&
                item.path === "/dashboard")
                ? "active"
                : ""
            }`}
          >
            <Link
              className="nav-link nav-align"
              to={item.path}
              onClick={() => handleMenuClick(item.path)} // Store active path on click
            >
              <span className="menu-title">{item.label}</span>
            </Link>
          </li>
        ))}
      </ul>
    </nav>
  );
};

export default SidebarComp;
