import React from "react";
import { Routes, Route } from "react-router-dom";

import Login from "../screens/authscreen/login";
import LandingScreen from "../screens/landingscreen/landingscreen";
import AddCompanyScreen from "../screens/addcompany/addcompany";
import AddCarScreen from "../screens/addcar/addcar";
import AddEmployeeScreen from "../screens/addemployee/addemployee";
import AddRideScreen from "../screens/addrides/addrides";
import AllEmployeeScreen from "../screens/allemployee/allemployee";
import AllCompanyeeScreen from "../screens/allcompany/allcompany";
import BrandManagementScreen from "../screens/brandManagement/brandManagement";
import AllUsersScreen from "../screens/allusers/allusers";
import AllSubscriberScreen from "../screens/allSubscribers/allSubscribers";
import AddUserScreen from "../screens/adduser/adduser";
import AllCarScreen from "../screens/allcar/allcar";
import AllRideScreen from "../screens/allrides/allrides";
import AvailableRideScreen from "../screens/allrides/availableride";
import DriverRides from "../screens/DriverRides/driverrides";
import AllDriverScreen from "../screens/alldriver/alldriver";
import AllReportScreen from "../screens/allreport/allreport";
import EditDriverScreen from "../screens/editdriverscreen/editdriver";
import EditCarScreen from "../screens/editcarscreen/editcar";
import ReportDetailsScreen from "../screens/reportdetails/reportdetails";
import MyRides from "../screens/ComapanyUserScreen/MyRides/MyRides";
import MyDriver from "../screens/ComapanyUserScreen/MyDriverScreen/MyDriver";
import MyCarScreen from "../screens/ComapanyUserScreen/MyCarScreen/MyCarScreen";
import MyEmployeeSc from "../screens/ComapanyUserScreen/myEmployeeScreen/myEmplyeeScreeen";
import AddCompanyCarScreen from "../screens/ComapanyUserScreen/addcarscreen/addCarScreen";
import AssignDriverCar from "../screens/assigndriver/AssignDriverCar";
import DriverEarningList from "../components/body/alldatacomponent/Earning/DriverEarningList";
import CompanyEarning from "../components/body/alldatacomponent/Earning/CompnyEarning";
import Profile from "../components/profile/Profile";
import EditCompanyScreen from "../screens/editCompanyScreen/editcompanyscreen";
import EditEmployeeScreen from "../screens/editEmployeeScreen/editemployeeScreen";
import DriverLocationComponent from "../components/map/DriverLocationComponents";
import ProtectedRoute from "../components/protectedRoute/ProtectedRoute";
import NotFound from "../components/notFound/NotFound";
// import BillingScreen from "../screens/Billing/BillingScreen";
import TransactionScreen from "../screens/Billing/TransactionScreen";
import UserProfile from "../components/profile/userProfile";
import OngoingRideScreen from "../screens/allrides/ongoingRideScreen";
import NotificationScreen from "../screens/notification/notificationScreen";
// import AddBrand from "../screens/addBrand/addBrand";
import AddBrandScreen from "../screens/addBrand/addBrand";
import EditBrandScreen from "../screens/editBrand/editBrand";
import CategoriesManagementScreen from "../screens/categoriesManagement/categoriesManagement";
import AddCategoryScreen from "../screens/addCategory/addCategory";
import EditCategoryScreen from "../screens/editCategory/editCategory";
import ProductManagementScreen from "../screens/productManagement/productManagement";
import AddProductScreen from "../screens/addProduct/addProduct";
import ViewProductScreen from "../components/viewProduct/viewProduct";
import AllAdminsScreen from "../screens/allAdmins/allAdmin";
import AddAdminScreen from "../screens/addAdmin/addAdmin";
import OrderManagementScreen from "../screens/orderManagement/orderManagement";
import ViewOrderScreen from "../components/viewOrder/vieworder";
import ReturnManagementScreen from "../screens/returnManagement/returnManagement";
import ViewReturnScreen from "../components/viewReturn/viewReturn";
import AllEmailTempScreen from "../screens/allEmailTemplates/allEmailTemps";
import OrderEmailTempScreen from "../screens/allEmailTemplates/orderEmailTemp";
import SignupEmailTempScreen from "../screens/allEmailTemplates/signupEmailTemp";
import FooterEmailTempScreen from "../screens/allEmailTemplates/footerEmailTemp";
// import EditProductMainComp from "../components/body/alldatacomponent/product/editProduct/editProductomp";
import EditProductScreen from "../screens/editProduct/editProduct";
import EditAdminScreen from "../screens/editAdmin/editAdmin";
import SubCategoriesManagementScreen from "../screens/subCategoriesManegement/subCategoriesManegement";
import AddSubCategoryScreen from "../screens/addSubCategory/addSubCategory";
import EditSubCategoryScreen from "../screens/editSubCategory/editSubCategory";
import WebSettingsManagementScreen from "../screens/webSettings/webSettings";
import EditUserScreen from "../screens/editUser/editUser";
import FinanceManagemntScreen from "../screens/finance/finance";
import AddReturnScreen from "../screens/addReturn/addReturn";
import NewsletterSubjectEmailTempScreen from "../screens/allEmailTemplates/newsletterSubjectEmailTemp";
import NewsletterEmailTempScreen from "../screens/allEmailTemplates/newsletterEmailTemp";

const AppRoutes = () => {
  return (
    <Routes>
      <Route path="/" element={<Login />} />
      <Route path="/dashboard" element={<ProtectedRoute />}>
        <Route index element={<LandingScreen />} />
        <Route path="add-company" element={<AddCompanyScreen />} />
        <Route path="add-car" element={<AddCarScreen />} />
        <Route path="add-employee" element={<AddEmployeeScreen />} />
        <Route path="add-ride" element={<AddRideScreen />} />
        <Route path="all-employee" element={<AllEmployeeScreen />} />
        <Route path="all-company" element={<AllCompanyeeScreen />} />
        {/* Brand Management */}
        <Route path="brand-management" element={<BrandManagementScreen />} />
        <Route path="brand-management/add" element={<AddBrandScreen />} />
        <Route path="brand-management/edit/:id" element={<EditBrandScreen />} />
        {/* Brand Management */}
        {/* Categories Management */}
        <Route
          path="categories-management"
          element={<CategoriesManagementScreen />}
        />
        <Route
          path="categories-management/add"
          element={<AddCategoryScreen />}
        />
        <Route
          path="categories-management/edit/:id"
          element={<EditCategoryScreen />}
        />
        {/* Categories Management */}

        {/* Sub categories */}
        <Route
          path="sub-categories-management"
          element={<SubCategoriesManagementScreen />}
        />
        <Route
          path="sub-categories-management/add"
          element={<AddSubCategoryScreen />}
        />
        <Route
          path="sub-categories-management/edit/:id"
          element={<EditSubCategoryScreen />}
        />
        {/* Sub categories */}

        {/* Product Management */}
        <Route
          path="product-management"
          element={<ProductManagementScreen />}
        />
        <Route path="product-management/add" element={<AddProductScreen />} />
        <Route
          path="product-management/edit/:id"
          element={<EditProductScreen />}
        />
        <Route path="product-management/:id" element={<ViewProductScreen />} />

        {/* Product Management */}
        {/* Order Management */}
        <Route path="order-management" element={<OrderManagementScreen />} />
        <Route path="order-management/view" element={<ViewOrderScreen />} />
        {/* Order Management */}
        {/* Return Management */}
        <Route path="return-management" element={<ReturnManagementScreen />} />
        <Route path="return-management/:id" element={<ViewReturnScreen />} />
        <Route path="return-management/add" element={<AddReturnScreen />} />

        {/* Return Management */}
        {/* Admin Management */}
        <Route path="admins-management" element={<AllAdminsScreen />} />
        <Route path="admins-management/add" element={<AddAdminScreen />} />
        <Route
          path="admins-management/edit/:id"
          element={<EditAdminScreen />}
        />
        {/* Admin Management */}

        {/* User Management */}
        <Route path="users-management" element={<AllUsersScreen />} />
        <Route path="users-management/add" element={<AddUserScreen />} />
        <Route path="users-management/edit/:id" element={<EditUserScreen />} />
        {/* User Management */}
        <Route path="subscriber-management" element={<AllSubscriberScreen />} />
        {/* Email Management */}
        <Route path="email-management" element={<AllEmailTempScreen />} />
        <Route
          path="email-management/order"
          element={<OrderEmailTempScreen />}
        />
        <Route
          path="email-management/signup"
          element={<SignupEmailTempScreen />}
        />
        <Route
          path="email-management/newsletter-subject"
          element={<NewsletterEmailTempScreen />}
        />
        <Route
          path="email-management/newsletter-subject"
          element={<NewsletterSubjectEmailTempScreen />}
        />
        <Route
          path="email-management/footer"
          element={<FooterEmailTempScreen />}
        />
        {/* Email Management */}

        {/* Web Settings */}

        <Route path="web-settings" element={<WebSettingsManagementScreen />} />

        {/* Web Settings */}

        {/* Web Settings */}

        <Route path="finance" element={<FinanceManagemntScreen />} />

        {/* Web Settings */}

        {/* <Route path="all-users" element={<AllUsersScreen />} /> */}
        {/* <Route path="add-user" element={<AddUserScreen />} /> */}
        {/* <Route path="all-cars" element={<AllCarScreen />} /> */}
        {/* <Route path="all-rides" element={<AllRideScreen />} /> */}
        {/* <Route path="all-drivers" element={<AllDriverScreen />} /> */}
        {/* <Route path="edit-driver/:driverId" element={<EditDriverScreen />} /> */}
        {/* <Route
          path="edit-employee/:employeeId"
          element={<EditEmployeeScreen />}
        /> */}
        {/* <Route path="edit-car/:carId" element={<EditCarScreen />} /> */}
        <Route path="edit-company/:companyId" element={<EditCompanyScreen />} />
        {/* <Route path="all-report" element={<AllReportScreen />} /> */}
        {/* <Route path="driver-rides/" element={<DriverRides />} /> */}
        {/* <Route
          path="report-details/:reportId"
          element={<ReportDetailsScreen />}
        /> */}
        {/* <Route path="my-rides" element={<MyRides />} /> */}
        {/* <Route path="ongoing-rides" element={<OngoingRideScreen />} /> */}
        {/* <Route path="all-available-rides" element={<AvailableRideScreen />} /> */}
        {/* <Route path="my-cars" element={<MyCarScreen />} /> */}
        {/* <Route path="my-employee" element={<MyEmployeeSc />} /> */}
        {/* <Route path="assigndrivercar" element={<AssignDriverCar />} /> */}
        {/* <Route path="my-driver" element={<MyDriver />} /> */}
        {/* <Route path="add-cars" element={<AddCompanyCarScreen />} /> */}
        {/* <Route
          path="all-driver-earning/:driverId/:companyId"
          element={<DriverEarningList />}
        /> */}
        {/* <Route
          path="all-company-earning/:companyId"
          element={<CompanyEarning />}
        /> */}
        <Route path="profile" element={<Profile />} />
        <Route path="profile/:userId" element={<UserProfile />} />
        {/* <Route path="driver-locations" element={<DriverLocationComponent />} /> */}
        {/* <Route path="billing" element={<BillingScreen />} /> */}
        {/* <Route path="transaction/:compId" element={<TransactionScreen />} /> */}
        <Route path="notifications/" element={<NotificationScreen />} />
      </Route>
      <Route path="*" element={<NotFound />} />
    </Routes>
  );
};

export default AppRoutes;
