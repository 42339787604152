import React, { useState, useEffect } from "react";
import { useLocation, useNavigate } from "react-router";
import { Container, Card, Col, Spinner, Button } from "react-bootstrap";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import { toast } from "react-toastify";
import { makeRequest } from "../../../../api/axiosService";

const OrderEmailTemplateMainComp = () => {
  // Simulate API data
  const location = useLocation();
  const orderTemplateData = location.state?.emailData;

  const isLoading = false;
  const error = null;
  const navigate = useNavigate();
  const orderTemplate = orderTemplateData;

  const [content, setContent] = useState("");

  useEffect(() => {
    if (orderTemplate) {
      setContent(orderTemplate);
    }
  }, [orderTemplate]);

  const handleEditorChange = (value) => {
    setContent(value);
  };

  const handleSave = async () => {
    try {
      const payload = { order_confirmation_template: content };
  
      const resp = await makeRequest(`/email-templates/3/order-confirmation//`, "PATCH", payload);
  
      if (resp) {
        toast.success("Order confirmation template updated successfully!");
      }
    } catch (error) {
      console.error("Failed to update Order confirmation template:", error);
      toast.error("Failed to update Order confirmation template.");
    }
  };  

  const handleBack = () => {
    navigate(-1); // Navigate to the previous page
  };

  if (isLoading) {
    return (
      <Container className="bg-white p-4 text-center">
        <h3>Loading...</h3>
      </Container>
    );
  }

  return (
    <Container>
      <Button onClick={handleBack} style={{ marginBottom: "1rem" }}>
        Go back
      </Button>
      <Col md={8} className="mt-2 mx-auto">
        <Card>
          <Card.Header>Edit and Preview Email Content</Card.Header>
          <Card.Body>
            {error ? (
              <p>Error loading data</p>
            ) : (
              <>
                <ReactQuill
                  value={content}
                  onChange={handleEditorChange}
                  theme="snow"
                  modules={{
                    toolbar: [
                      [{ header: "1" }, { header: "2" }],
                      ["bold", "italic", "underline"],
                      [{ list: "ordered" }, { list: "bullet" }],
                      ["link"],
                      ["clean"],
                    ],
                  }}
                />

                <hr />

                <h5>Preview:</h5>
                <div
                  style={{
                    fontFamily: "Arial, sans-serif",
                    color: "#333",
                    width: "500px",
                    margin: "auto",
                    padding: "20px",
                    border: "1px solid #ddd",
                    borderRadius: "8px",
                  }}
                  dangerouslySetInnerHTML={{
                    __html: `
                      <p style="font-size: 16px; line-height: 1.5;">${content}</p>
                      <div className="table-responsive mb-3">
                        <table className="table table-bordered">
                          <thead>
                            <tr>
                              <th>Product ID</th>
                              <th>Quantity</th>
                              <th>Subtotal</th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr>
                              <td>P001</td>
                              <td>2</td>
                              <td>$40.00</td>
                            </tr>
                            <tr>
                              <td>P002</td>
                              <td>1</td>
                              <td>$30.00</td>
                            </tr>
                            <tr>
                              <td>P003</td>
                              <td>3</td>
                              <td>$90.00</td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                      <p className="font-weight-bold text-right">
                        <strong>Total Amount:</strong> $160.00
                      </p>
                    `,
                  }}
                />
              </>
            )}
          </Card.Body>
        </Card>
        <Button variant="primary" onClick={handleSave} className="mt-3">
          Save Changes
        </Button>
      </Col>
    </Container>
  );
};

export default OrderEmailTemplateMainComp;
