import React, { useState, useEffect } from "react";
import { useLocation, useNavigate } from "react-router";
// import { DashboardLayout } from "../../Components/Layout/DashboardLayout";
import { Container, Card, Col, Spinner, Button } from "react-bootstrap";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import { toast } from "react-toastify";
import { makeRequest } from "../../../../api/axiosService";

const NewsLetterSubjectTemplate = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const newsLetterTemplateData = location.state?.emailData;

  const isLoading = false;
  const error = null;

  const newsletterSubjectTemplate = newsLetterTemplateData;

  // Content state for the editor
  const [content, setContent] = useState("");

  useEffect(() => {
    if (newsletterSubjectTemplate) {
      setContent(newsletterSubjectTemplate);
    }
  }, [newsletterSubjectTemplate]);

  // Handle editor changes
  const handleEditorChange = (value) => {
    setContent(value);
  };

  const handleSave = async () => {
    try {
      const payload = { newsletter_subject_template: content };
  
      const resp = await makeRequest(`/email-templates/3/newsletter/`, "PATCH", payload);
  
      if (resp) {
        toast.success("Newsletter subject template updated successfully!");
      }
    } catch (error) {
      console.error("Failed to update newsletter subject template:", error);
      toast.error("Failed to update newsletter subject template.");
    }
  };  

  const handleBack = () => {
    navigate(-1);
  };

  return (
    <Container>
      <Button onClick={handleBack}>Go Back</Button>
      <Col md={8} className="mt-2 mx-auto">
        <Card>
          <Card.Header>Edit and Preview Email Content</Card.Header>
          <Card.Body>
            {isLoading ? (
              <Spinner animation="border" />
            ) : error ? (
              <p>Error loading data</p>
            ) : (
              <>
                <ReactQuill
                  value={content}
                  onChange={handleEditorChange}
                  theme="snow"
                  modules={{
                    toolbar: [
                      [{ header: "1" }, { header: "2" }],
                      ["bold", "italic", "underline"],
                      [{ list: "ordered" }, { list: "bullet" }],
                      ["link"],
                      ["clean"],
                    ],
                  }}
                />
                <hr />
                <h5>Preview:</h5>
                <div
                  style={{
                    fontFamily: "Arial, sans-serif",
                    color: "#333",
                    maxWidth: "500px",
                    margin: "auto",
                    padding: "20px",
                    border: "1px solid #ddd",
                    borderRadius: "8px",
                  }}
                  dangerouslySetInnerHTML={{
                    __html: `<div>${content}</div>`,
                  }}
                />
              </>
            )}
          </Card.Body>
        </Card>
        <Button variant="primary" onClick={handleSave} className="mt-3">
          Save Changes
        </Button>
      </Col>
    </Container>
  );
};

export default NewsLetterSubjectTemplate;
